import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import isEmpty from 'lodash/isEmpty';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import TAB_SLUGS from '@constants/tabSlugs';
import {
  selectModuleAddons,
  selectModuleConfigClientPanel,
  selectModuleShop,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';

import {
  OrderFormCustomer,
  OrderFormDelivery,
  OrderFormSummary,
} from './components';

const OrderFormDishes = dynamic(() => import('./components/Dishes/Dishes'));
const OrderFormAddons = dynamic(() =>
  import('./components/OrderFormAddonsView')
);
const OrderFormPremium = dynamic(() => import('./components/Premium'));
const AddonsShop = dynamic(() => import('./components/AddonsShop'));
const OrderFormDietsFull = dynamic(() =>
  import('./components/Diets/Full/DietsFull.container')
);
const AdditionalMealTypes = dynamic(() =>
  import('./components/AdditionalMealTypes/AdditionalMealTypes.container')
);

const ChoicesMenu = dynamic(() => import('./components/ChoicesMenu/index'));

const useTabsConfig = () => {
  const { t } = useTranslation();

  const { isDiet, isShop, isDietShop } = useAppMode();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { currentDiet } = useBasketMethods();
  const { addToOrder } = useAppConfigSelector(selectModuleAddons);

  const {
    changeMenuMode,
    premiumAdditionalStep,
    enableAdditionalMealTypesStep,
    allowSelectMenuBeforeOrderDiet,
  } = useAppConfigSelector(selectModuleConfigClientPanel);
  const { clientPanelShowOnNewOrder } = useAppConfigSelector(selectModuleShop);

  const isSubscription =
    currentDiet?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

  const tabs = useMemo(() => {
    const commonTabs = [
      ...(!isAuthenticated
        ? [
            {
              id: 6,
              label: t('$*orderFormTabs.customer', 'Dane klienta'),
              panel: OrderFormCustomer,
              slug: TAB_SLUGS.AUTHORIZATION,
            },
          ]
        : []),
      {
        id: 7,
        label: t('$*orderFormTabs.delivery', 'Dane dostawy'),
        panel: OrderFormDelivery,
        slug: TAB_SLUGS.DELIVERY_DATA,
      },
      {
        id: 8,
        label: t('$*orderFormTabs.summary', 'Podsumowanie'),
        panel: OrderFormSummary,
        slug: TAB_SLUGS.SUMMARY,
      },
    ];

    const shopModeTabs = [
      ...(!isSubscription
        ? [
            {
              id: 5,
              label: t('$*orderFormTabs.selectDishes', 'Wybierz dania'),
              panel: OrderFormDishes,
              slug: TAB_SLUGS.SELECT_DISHES,
            },
            ...(addToOrder && isEmpty(currentDiet)
              ? [
                  {
                    id: 9,
                    label: t('$*orderFormTabs.selectAddons', 'Dodatki'),
                    panel: AddonsShop,
                    slug: TAB_SLUGS.SELECT_ADDONS,
                  },
                ]
              : []),
          ]
        : []),
    ];

    const dietModeTabs = [
      {
        id: 1,
        label: t('$*orderFormTabs.selectDiet', 'Wybierz diete'),
        panel: OrderFormDietsFull,
        slug: TAB_SLUGS.SELECT_DIET,
      },
      ...(enableAdditionalMealTypesStep &&
      currentDiet.allowStepWithAdditionalMealTypes
        ? [
            {
              id: 2,
              label: t(
                '$*orderFormTabs.additionalMealTypes',
                'Dodatkowe porcje'
              ),
              panel: AdditionalMealTypes,
              slug: TAB_SLUGS.ADDITIONAL_MEALTYPES,
            },
          ]
        : []),

      ...(allowSelectMenuBeforeOrderDiet &&
      currentDiet.hasDietsToSelectMenu &&
      changeMenuMode === 'STANDARD'
        ? [
            {
              id: 3,
              label: t('$*orderFormTabs.choicesMenu', 'Wybierz menu'),
              panel: ChoicesMenu,
              slug: TAB_SLUGS.CHOICES_DISH,
            },
          ]
        : []),

      ...(addToOrder
        ? [
            {
              id: 4,
              label: t('$*orderFormTabs.selectAddons', 'Dodatki'),
              panel: OrderFormAddons,
              slug: TAB_SLUGS.SELECT_ADDONS,
            },
          ]
        : []),
      ...(changeMenuMode === 'PREMIUM' && premiumAdditionalStep
        ? [
            {
              id: 5,
              label: t('$*orderFormTabs.selectPremium', 'Premium'),
              panel: OrderFormPremium,
              slug: TAB_SLUGS.PREMIUM,
            },
          ]
        : []),
    ];

    return [
      ...(isDiet ? dietModeTabs : []),
      ...((isShop && !isDiet) || (isDietShop && clientPanelShowOnNewOrder)
        ? shopModeTabs
        : []),
      ...commonTabs,
    ];
  }, [
    addToOrder,
    changeMenuMode,
    isSubscription,
    isAuthenticated,
    isShop,
    t,
    currentDiet.allowStepWithAdditionalMealTypes,
    currentDiet.hasDietsToSelectMenu,
  ]);

  return tabs;
};

export default useTabsConfig;
